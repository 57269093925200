.contenedor_galeria{
    width: 100%;
    background-color: #09242F;
    padding: 155px 50px 50px;
    position: relative;
    @media (max-width:1024px) {
        padding: 200px 40px 20px;
    }
    @media (max-width:550px) {
        padding: 150px 40px 20px;
    }
    .back{
        color: white;
        text-decoration: none;
        font-family: "montserrat regular";
        font-size: 30px;
        position: absolute;
        top: 10%;
        right: 2%;
        @media (max-width:1024px) {
            top: 8%;
            right: 5%;
        }
        @media (max-width:550px) {
            top: 4%;
            right: 10%;
            font-size: 25px;
        }
    }
    .grid{
        display: flex;
        flex-wrap: wrap;    
        column-gap: 20px;
        row-gap: 20px;
        justify-content:center;
        .img-grid{
            width: 23%;
            height: 100%;
            position: relative;
            cursor: pointer;
            overflow: hidden;
            @media (max-width:1024px) {
                width: 48%;;
            }
            @media (max-width:550px) {
                width: 100%;
            }
            img{
                width: 100%;
                height: 100%;
                transform: scale(1);
                transition: all 0.2s ease-in;
            }
            video{
                width: 100%;
                height: 100%;
            }
            &:hover{
                img{
                    transform: scale(1.1);
                }
                .caption{
                    opacity: 1;
                }
            }
            .caption{
                opacity: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                position: absolute;
                top: 0;
                display: flex;
                align-items: end;
                transition: all 0.5s ease-in;
                p{
                    padding: 20px;
                    color: white;
                    font-family: montserrat regular;
                    font-size: 17px;
                }
            }
        }    
    }
}
.modal-galeria{
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.726);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    .closed{
        position: absolute;
        right: 15%;
        font-size: 30px;
        color: white;
        top: 10%;
        cursor: pointer;
        font-family: "montserrat regular";
        @media (max-width:1024px) {
            top: 25%;
            right: 20px;
        }
        @media (max-width: 550px) {
            font-size: 20px;
            top: 30%;
        }
    }
    .modalG{
        width: 60%;
        height: 90%;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width:1024px) {
            height: 40%;
            width: 90%;
        }
        img,video{
            width: 100%;
            object-fit: contain;
        }
    }
}