.contenedor_mapa{
    width: 100%;
    height: 95vh;
    display: flex;
    background-color: #C6A77F;
    align-items: center;
    min-height: 550px;
    @media (max-width:1024px) {
       flex-direction: column-reverse;
       height: auto;
    }
    .mapa{
        width: 50%;
        height: 100%;
        @media (max-width:1024px) {
            width: 100%;
            height: 70vh;
        }
        @media (max-width:550px) {
            height: 60vh;
        }
    }
    .ubicaciones{
        width: 50%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
        @media (min-width:1600px) {
            gap: 50px;
        }
        @media (max-width:1024px) {
            width: 100%;
            gap: 40px;
            justify-content: center;
            padding: 100px 0;
        }
        @media (max-width:550px) {
            justify-content: flex-start;
            padding: 80px 0;
        }
        .top{
            width: 80%;
            gap: 80px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            @media (max-width:1024px) {
                gap: 90px;
                width: 65%;
            }
            @media (max-width:860px) {
                width: 75%;
             }
            @media (max-width:550px) {
                gap: 70px;
            }
            &_subtitulo{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                h2{
                    font-weight: normal;
                    color: #09242F;
                    font-size: 40px;
                    text-align: center;
                    @media (max-width:550px) {
                        font-size: 28px;
                    }
                }
            }
            &_texto{
                width: 65%;
                @media (min-width:1600px) {
                    width: 65%;
                }
                @media (max-width:550px) {
                    width: 100%;
                }
                p{
                    color: #09242F;
                    text-align: center;
                    @media (min-width:1600px) {
                        font-size: 20px;
                    }
                    @media (max-width:550px) {
                        font-size: 15px;
                    }
                }
            }
        }
        .bottom {
            width: 80%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            gap: 40px;
            @media (min-width:1600px) {
                gap: 50px;
            }
            @media (max-width:1024px) {
                width: 65%;
                gap: 40px;
            }
            @media (max-width:550px) {
                margin: 0;
                width: 95%;
            }
            &_ubicaciones{
                width: auto;
                .row_list{
                    display: flex;
                    align-items: center;
                    margin-bottom: 7px;
                    @media (min-width:1600px) {
                        margin-bottom: 10px;
                    }
                    img{
                        width: 25px;
                        margin-right: 10px;
                    }
                    p{
                        color: #09242F;
                        font-family: "montserrat regular";
                        @media (min-width:1600px) {
                            font-size: 17px;
                        }
                        @media (max-width:550px) {
                            font-size: 14px;
                        }
                    }
                }
            }
            .mapas{
                width: 60%;
                display: flex;
                justify-content: space-between;
                @media (min-width:1600px) {
                    width: 50%;
                }
                @media (max-width:550px) {
                    width: 65%;
                }
                img{
                    width: 150px;
                    @media (max-width:550px) {
                        width: 110px;
                    }
                }
                a{
                    &:nth-child(2){
                        img{
                            width: 110px;
                            @media (max-width:550px) {
                                width: 80px;
                            }
                        }
                    }
                }
            }
        }
    }
}