*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body {
    font-size: 16px;
    font-family: "libre baskerville";
    @media (min-width:1600px) {
        font-size: 16px;
    }
    @media (max-width:550px) {
        font-size: 12px;
    }
}

@font-face {
    font-family: "libre baskerville";
    src: url(./Tipografias/LIBREBASKERVILLE-REGULAR.TTF);
}
@font-face {
    font-family: "montserrat medium";
    src: url(./Tipografias/Montserrat-Medium.otf);
}
@font-face {
    font-family: "montserrat regular";
    src: url(./Tipografias/Montserrat-Regular.otf);
}
@font-face {
    font-family: "montserrat italic";
    src: url(./Tipografias/MONTSERRAT-ITALIC.TTF);
}
.scroll{
    /* Layout Properties */
top: 921px;
left: 946px;
width: 28px;
height: 60px;
/* UI Properties */
opacity: 1;
}
.swiper-slide img{
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
}
.show-enter{
    opacity: 0;
    transform: scale(0.9);
}
.show-enter-active {
    opacity: 0.5;
    transform: translateX(0);
    transition: opacity 0.1s, transform 0.5s;
}
.show-enter-done  {
    opacity: 1;
}
.show-exit {
    opacity: 1;
}
.show-exit-active {
    opacity: 0.5;
    transform: scale(0.9);
    transition: opacity 0.1s, transform 0.5s;
}
.show-exit-done {
    opacity: 0;
}


.hide-enter{
    opacity: 0;
    // transform: scale(0.9);
}
.hide-enter-active {
    opacity: 0;
    // transform: translateX(0);
    transition: opacity 0.1s;
}
.hide-enter-done  {
    opacity: 1;
    transition: opacity 0.1s;
}
.hide-exit {
    opacity: 1;
}
.hide-exit-active {
    opacity: 0;
    // transform: scale(0.9);
    transition: opacity 0.1s;
}
.hide-exit-done {
    opacity: 0;
}

.swal-overlay{
    z-index: 999999;
}
.swal-text{
    text-align: center;
}