.prototypes{
    width: 100%;
    height: 100%;
    background-color: #09242F;
    padding: 150px 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width:1600px) {
        padding: 150px 250px;
    }
    @media (max-width: 1024px) {
        padding: 100px 80px;
    }
    @media (max-width: 550px) {
        padding: 80px 30px;
    }
    .tap-prototypes{
        display: flex;
        gap: 50px;
        width: fit-content;
        position: relative;
        @media (max-width: 550px) {
            gap: 20px;
        }
        .linea{
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 100px;
            height: 2px;
            background-color: #C6A77F;
            transition: left 0.5s ease;
        }
        button{
            padding: 0 10px;
            background-color: transparent;
            color: #C6A77F;
            border: none;
            cursor: pointer;
            font-size: 25px;
            font-family: "libre baskerville";
            @media (max-width: 550px) {
                font-size: 17px;
            }
        }
    }
    .detail{
        width: 100%;
        display: flex;
        padding-top: 150px;
        @media (max-width: 1024px) {
            flex-direction: column;
            gap: 90px;
        }
        @media (max-width: 550px) {
            padding-top: 75px;
        }
        .left-detail{
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 40px;
            @media (min-width:1600px) {
                gap: 50px;
            }
            @media (max-width: 1024px) {
                width: 100%;
                padding-left: 10px;
            }
            h1{
                font-weight: normal;
                font-size: 60px;
                color: #C6A77F;
                @media (max-width: 550px) {
                   font-size: 40px;
                }
            }
            ul{
                color: white;
                font-family: "montserrat medium";
                display: flex;
                flex-direction: column;
                gap: 3px;
            }
            a{
                    cursor: pointer;
                    padding: 10px 20px;
                    background-color: #C6A77F;
                    color: #373A36;
                    text-decoration: none;
                    transition: background-color 0.5s ease, color 0.5s ease;
                    &:hover{
                        background-color: #373A36;
                        color: white;
                    }
            }
            .left-detail-row{
                width: 80%;
                display: flex;
                padding-bottom: 50px;
                gap: 50px;
                @media (max-width: 1024px) {
                    width: 100%;
                    padding-bottom: 0;
                }
                .left-detail-row-left{
                    width: 40%;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    h3{
                        color: white;
                        font-family: "montserrat medium";
                        font-weight: 100;
                    }
                    ul{
                        color: white;
                        font-family: "montserrat medium";
                        display: flex;
                        flex-direction: column;
                        gap: 3px;
                    }
                }
                .left-detail-row-right{
                    width: 35%;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    h3{
                        color: white;
                        font-family: "montserrat medium";
                        font-weight: 100;
                    }
                    ul{
                        color: white;
                        font-family: "montserrat medium";
                        display: flex;
                        flex-direction: column;
                        gap: 3px;
                    }
                }
            }
        }
        .right-detail{
            width: 50%;
            @media (max-width: 1024px) {
                width: 100%;
                height: 580px;
            }
            @media (max-width: 550px) {
                height: 360px;
            }
            .modal-slider{
                position: fixed;
                background-color: rgba(0, 0, 0, 0.907);
                z-index: 99999;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              
                .slider{
                    width: 60%;
                    height: 80%;
                    position: relative;
                    @media (max-width: 1024px) {
                        width: 90%;
                        height: 400px;
                    }
                    @media (max-width: 550px) {
                        width: 90%;
                    }
                    button{
                        color: #ffff;
                        position: absolute;
                        z-index: 9999;
                        top: 0;
                        right: -5%;
                        background-color: transparent;
                        border: none;
                        font-size: 30px;
                        cursor: pointer;
                        @media (max-width: 1024px) {
                            top: -10%;
                            right: 0%;
                        }
                        @media (max-width: 550px) {
                            top: -20%;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}