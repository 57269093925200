.contenedor_amenidades{
    background-color: #C6A77F;
    width: 100%;
    padding: 150px 0;
    @media (max-width:1024px) {
        padding: 100px 0;
    }
    @media (max-width:550px) {
        padding: 0;
        padding: 80px 0;
        display: flex;
        flex-direction: column;
        gap: 70px;
    }
    .amenidades_top{
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 35%;
        @media (max-width:1024px) {
            flex-direction: column;
            height: 70%;
            gap: 90px;
        }
        @media (max-width:550px) {
           height: 60%;
           gap: 70px;
        }
        .animation-container{
            width: 40%;
            @media (max-width:1024px) {
                width: 100%;
            }
        }
        .subtitulo_ame{
            width: 100%;
            padding: 20px 150px;
            display: flex;
            flex-direction: column;
            gap: 50px;
            @media (max-width:1024px) {
                padding: 0 100px;
                gap: 90px   ;
            }
            @media (max-width:550px) {
                padding: 0 25px;
                gap: 70px;
            }
            h2{
                font-family: "libre baskerville";
                font-weight: normal;
                color: #373A36;
                font-size: 40px;
                @media (max-width:550px) {
                    font-size: 28px;
                    text-align: center;
                }
            }
            p{
                font-family: "montserrat regular";
                @media (max-width:550px) {
                    text-align: center;
                    padding-bottom: 7px;
                }
            }
        }
        .list_ame{
            display: flex;
            flex-wrap: wrap;
            width: 60%;
            justify-content: space-between;
            gap: 30px;
            padding: 0 100px;
            @media (max-width:1024px) {
                width: 90%;
                height: 50%;
                padding: 0 50px;
            }
            @media (max-width:550px) {
                width: 85%;
                height: 60%;
                padding: 0;
            }
            .colum_list{
                width: 45%;
                display: flex;
                align-items: center;
                @media (max-width:550px) {
                    width: 40%;
                }
                p{
                    font-family: "montserrat regular";
                    color: #373A36;
                    @media (max-width:550px) {
                        font-size: 13px;
                    }
                }
                img{
                    width: 40px;
                    margin-right: 20px;
                    @media (max-width:550px) {
                       width: 30px;
                       margin-right: 10px;
                    }
                }
            }
        }
    }
    .amenidades_bottom{
        width: 100%;
        height: 60%;
        padding: 150px 80px 0;
        @media (min-width:1600px) {
            padding: 20px 80px 0;
            padding-top: 150px;
            height: 65%;
        }
        @media (max-width:1024px) {
           padding: 100px 80px 0;
        }
        @media (max-width:550px) {
            padding: 0 25px;
        }
        .swiper{
            width: 100%;
            height: 100%;
            @media (max-width:1024px) {
                height: 580px;
             }
             @media (max-width:550px) {
                 height: 360px;
             }
            --swiper-theme-color: #ffffff;
            .swiper-pagination-horizontal{
                top: auto;
            }
            .swiper-pagination-bullet{
                opacity: 1 !important;
            }
            .swiper-pagination-bullet-active{
                background-color: #09242F !important;
            }
        }
        .contenedor__amenidades_carousel{
            width: 100%;
            height: 100%;
            &__slider{
                width: 100%;
                height: 100%;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}