.contenedor_recorrido{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    @media (max-width:1024px) {
        height: 70vh;
    }
    @media (max-width:550px) {
        height: 60vh;
    }
    .disclamer{
        position: absolute;
        color: white;
        bottom: 100px;
        text-align: center;
        font-family: "montserrat regular";
        font-size: 12px;
        font-size: 85%;
        @media (max-width:550px) {
            font-size: 65%;
            bottom: 80px;
        }
    }
    iframe{
        width: 100%;
        height: 100%;
        border: none;
        z-index: 0;
    }
    .options{
        background-color: #C6A77F;
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        gap: 80px;
        bottom: 0;
        @media (max-width:550px) {
            height: 40px;
            gap: 20px;
        }
        button{
            cursor: pointer;
            background-color: transparent;
            border: none;
            color: #09242F;
            font-size: 25px;
            font-family: "libre baskerville";
            padding: 0 10px;
            @media (max-width:550px) {
                font-size: 18px;
            }
            &:hover{
                color: #09242F;
            }
            &.active{
                color: #09242F;
                background-size: 100% 2px;
                text-decoration: none;
            }
            background-image: linear-gradient(currentColor, currentColor);
            background-position: 0% 85%;
            background-repeat: no-repeat;
            background-size: 0% 2px;
            transition: background-size 0.5s;
            &:hover{
                background-size: 100% 2px;
                text-decoration: none;
            }
        }
    }
}