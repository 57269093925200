.contenedor_stiky{
    display: flex;
    position: fixed;
    right: -165px;
    top: 45%;
    z-index: 9999;
    transform: rotate(270deg);
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    @media (max-width:550px) {
        right: -116px;
    }
    &.mostrar{
        @media (max-width:550px) {
            display: flex;
        }
    }
    &.ocultar{
        @media (max-width:550px) {
            display: none;
        }
    }
    .brochure,.disponibilidad{
        background-color: #C6A77F;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: libre baskerville;
    }
    .brochure{
        width: auto;
        padding: 5px 20px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        @media (max-width:550px) {
            padding: 4px 10px;
        }
        p{
            cursor: pointer;
            color: #09242F;
        }
    }
    .disponibilidad{
        width: auto;
        padding: 5px 20px;
        background-color: #09242F;
        box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19);
        @media (max-width:550px) {
            padding: 4px 10px;
        }
        a{
            text-decoration: none;
            color: #fff;
            cursor: pointer;
        }
    }
}