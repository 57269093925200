.contenedor_modal_video{
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: #09242F;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    .closed{
        font-size: 30px;
        color: white;
        font-family: "montserrat regular";
        position: absolute;
        top: 5%;
        right: 5%;
        cursor: pointer;
    }
    iframe{
        width: 80%;
        height: 90%;
        border: none;
        @media (max-width:550px) {
            width: 95%;
            height: 80%;
        }
    }
}