

.contenedor__principal{
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: #09242F;
    @media (max-width:550px) {
        min-height: 700px;
    }
    &_carousel{
        width: 100%;
        height: 100%;
        .swiper{
            width: 100%;
            height: 100%;
        }
        &__slider{
            width: 100%;
            height: 100%;
            background-color: #09242F;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: bottom;
                @media (max-width:550px) {
                    object-fit: contain;
                    object-position: center;
                }
            }
        }
    }
    .bottom_info{
        background-color: #09242F;
        width: 100%;
        height: 50px;
        position: absolute;
        z-index: 9998;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (min-width:1600px) {
            height: 50px;
        }
        .whatsapp{
            position: fixed;
            left: 30px;
            width: 30%;
            @media (max-width:550px) {
                left: 10px;
            }
            div{
                background-color: #09242F;
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                @media (min-width:1600px) {
                    height: 50px;
                    width: 50px;
                }
                @media (max-width:550px) {
                    width: 50px;
                    display: none;
                }
                &.mostrar{
                    @media (max-width:550px) {
                        display: flex;
                    }
                }
                &.ocultar{
                    @media (max-width:550px) {
                        display: none;
                    }
                }
                a{
                    
                    img{
                        width: 25px;
                    }
                }
            }

        }
        .texto{
            width: 100%;
            display: flex;
            justify-content: center;
            @media (max-width:550px) {
                justify-content: center;
            }
            p{
                font-size: 18px;
                color: #fff;
                letter-spacing: 5px;
                @media (max-width:550px) {
                    font-size: 14px;
                    letter-spacing: 2px;
                }
            }
        }
        .icon-scroll,
        .icon-scroll:before{
            position: absolute;
            left: 50%;
        }
        .icon-scroll{
            width: 28px;
            height: 50px;
            margin-left: -20px;
            top: -50%;
            margin-top: -50px;
            box-shadow: inset 0 0 0 1px #C6A77F;
            border-radius: 25px;
        }
        .icon-scroll:before{
            content: '';
            width: 8px;
            height: 8px;
            background: #C6A77F;
            margin-left: -4px;
            top: 8px;
            border-radius: 4px;
            animation-duration: 1.5s;
            animation-iteration-count: infinite;
            animation-name: scroll;
        }
        @keyframes scroll{
            0%{
                opacity: 1;
            }
            100%{
                opacity: 0;
                transform: translateY(46px);
            }
         }
    }
}