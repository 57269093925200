.contenedor_modal{
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: #09242F;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    top: 0;
    .closed{
        font-size: 30px;
        color: white;
        font-family: "montserrat regular";
        position: absolute;
        top: 5%;
        right: 10%;
        cursor: pointer;
    }
    &_form{
        width: 60%;
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        @media (min-width:1600px) {
            height: 80%;
            justify-content: space-between;
        }
        @media (max-width:1024px) {
            height: 60%;
        }
        @media (max-width:550px) {
            height: 80%;
            width: 80%;
            min-height: 590px;
        }
        img{
            width: 150px;
            @media (min-width:1600px) {
                width: 250px;
            }
        }
        p{
            color: white;
            font-family: "montserrat regular";
            @media (max-width:550px) {
               text-align: center;
               margin: 0 0 20px;
            }
        }
        .error{
            color: rgb(211, 2, 2);
            font-size: 13px;
            font-family: "montserrat regular";
        }
        form{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 80%;
            height: 60%;
            @media (min-width:1600px) {
                min-height: 450px;
            }
            @media (max-width:550px) {
                width: 100%;
            }
            
            input{
                width: 50%;
                height: 30px;
                background-color: transparent;
                border: none;
                border-bottom: 1px solid #C2BA98;
                outline: none;
                color: white;
                font-size: 15px;
                font-family: "montserrat regular";
                @media (min-width:1600px) {
                    width: 40%;
                    height: 40px;
                }
                @media (max-width:1024px) {
                    width: 80%;
                }
                @media (max-width:550px) {
                    width: 100%;
                }
                &::placeholder{
                    text-align: center;
                    color: white;
                    font-family: "montserrat regular";
                    font-size: 15px;
                    @media (min-width:1600px) {
                        font-size: 18px;
                    }
                }
            }
            /* Change the white to any color */
            input:-webkit-autofill,
            input:-webkit-autofill:hover, 
            input:-webkit-autofill:focus, 
            input:-webkit-autofill:active{
                -webkit-box-shadow: 0 0 0 30px #373A36 inset !important;
            }
            /*Change text in autofill textbox*/
            input:-webkit-autofill{
                -webkit-text-fill-color: white !important;
            }
            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            input[type=number] {
                -moz-appearance: textfield;
            }
            .button_send{
                cursor: pointer;
                padding: 10px 20px;
                border: 1px solid transparent;
                background-color: #C2BA98;
                color: #373A36;
                font-size: 18px;
                &:hover{
                    background-color: #373A36;
                    color: #C2BA98;
                    border: 1px solid #C2BA98;
                }
            }
            p{
                font-family: "libre baskerville";
                font-style: italic;
                font-size: 12px;
                a{
                    color: white;
                    cursor: pointer;
                    &:hover{
                        color: #C2BA98;
                    }
                }
            }
        }
    }
}