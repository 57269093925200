

.contenedor_conceptos{
    width: 100%;
    height: auto;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 150px 0;
    @media (max-width:1024px) {
        flex-direction: column;
        height: auto;
        padding: 100px 0;
        gap: 90px;
    }
    @media (max-width:550px) {
        padding: 80px 0;
        gap: 70px;
    }
    &.background{
        background-color: #09242F;
        h2,p{
            color: white;
        }
    }
    &.reverse{
        flex-direction: row-reverse;
        @media (max-width:1024px) {
            flex-direction: column;
        }
        .contenedor_conceptos__left{
            width: 30%;
            padding-right: 50px;
            @media (max-width:1024px) {
                width: 100%;
                padding-right: 0;
            }
            @media (min-width:1600px) {
                padding-left: 0;
                padding-right: 100px;
            }
        }
    }
    &__left{
        width: 25%;
        height: 80%;
        gap: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        @media (min-width:1600px) {
            height: 60%;
            width: 24%;
            padding-left: 80px;
            gap: 70px;
        }
        @media (max-width:1024px) {
            width: 100%;
            height: auto;
            align-items: center;
            gap: 90px;
        }
        @media (max-width:550px) {
            height: auto;
            align-items: center;
            gap: 70px;
        }
        &_subtitulo{
            display: flex;
            flex-direction: column;
            justify-content: center;
            h2{
                font-weight: normal;
                color: #373A36;
                font-size: 40px;
                @media (max-width:1024px) {
                    text-align: center;
                }
                @media (max-width:550px) {
                    font-size: 28px;
                }
            }
        }
        &_texto{
            @media (max-width:1024px) {
                width: 70%;
            }
            @media (max-width:550px) {
                width: 80%;
            }
            p{
                font-family: "montserrat regular";
                color: #373A36;
                @media (max-width:1024px) {
                    text-align: center;
                }
                @media (max-width:550px) {
                    font-size: 14px;
                    padding-bottom: 7px;
                }
            }
        }
        &_button{
            padding: 10px 40px 10px;
            @media (max-width:550px) {
                padding: 10px 0;
                padding-left: 0;
            }
            a{
                cursor: pointer;
                padding: 10px 20px;
                background-color: #C6A77F;
                color: #373A36;
                text-decoration: none;
                transition: background-color 0.5s ease, color 0.5s ease;
                &:hover{
                    background-color: #373A36;
                    color: white;
                }
            }
        }
    }
    &__right{
        width: 50%;
        height: 60%;
        position: relative;
        @media (min-width:1600px) {
            width: 50%;
            height: 60%;
        }
        @media (max-width:1024px) {
            width: 100%;
            height: 40%;
            padding: 0 80px;
        }
        @media (max-width:550px) {
            height: auto;
            padding: 0 25px;
        }
        .play{
            position: absolute;
            bottom: 0;
            top: 0;
            right: 0;
            left: 0;
            margin: auto;
            width: 100px;
            z-index: 9;
            cursor: pointer;
            @media (max-width:550px) {
                width: 60px;
            }
        }
        &_video{
            position: relative;
            height: 100%;
            @media (max-width:1024px) {
                width: 100%;
                height: 580px;
            }
            @media (max-width: 550px) {
                height: 360px;
            }
            video{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &_img{
            width: 100%;
            height: 100%;
            @media (max-width:1024px) {
                height: 580px;
            }
            @media (max-width: 550px) {
                height: 360px;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &_slider{
            height: 100%;
            @media (max-width:1024px) {
                height: 580px;
            }
            @media (max-width: 550px) {
                height: 360px;
            }
            .concepto-carousel-slides{
                .swiper-pagination-horizontal{
                    top: auto;
                }
                .swiper-pagination-bullet{
                    opacity: 1 !important;
                }
                .swiper-pagination-bullet-active{
                    background-color: #09242F !important;
                }
            }
        }
    }
}