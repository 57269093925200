.slider-galeria{
    width: 100%;
    height: 95vh;
    @media (max-width:1024px) {
        height: 450px;
    }
    @media (max-width:550px) {
        height: 300px;
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: bottom;
    }
}
.swiper{
    width: 100%;
    height: 100%;
}